import React, { useState, useEffect, useRef, useContext } from "react";
import { Toast, ToastContainer,Button, Row, Col, Badge } from 'react-bootstrap'; 
import io from "socket.io-client";
import AgentChat from "./AgentChat"; 
import Ringer from "./AgentRinger";
import Peer from "simple-peer"; 
import AgentCallWindow from "./AgentCallWindow";
import CallToasts from './AgentCallToasts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faBell, faMessage, faUserGroup, faCalendar, faPhone, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import SimplePeer from "simple-peer";

//const socket = io('http://localhost:5000/agent'); 

// This component should be protected, only accessible when an agent is logged in
// You can use React Router or state management for protected routes.


const AgentDashboard = ({socket, agentInfo}) => { 
    const [messages, setMessages] = useState([]); 
    const [availableRooms, setAvailableRooms] = useState([]); 
    const [currentChat, setCurrentChat] = useState(null); 
    const [availableAgents, setAvailableAgents] = useState([]); 
    const [roomId, setRoomid] = useState('');
  //  const [availableClients, setAvailableClients] = useState([]);
    const [messagesByRoom, setMessagesByRoom] = useState({});

    const [waitingClients, setWaitingClients] = useState([]);
    const [connectedClients, setConnectedClients] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [toastQueue, setToastQueue] = useState([]);
    const [currentToast, setCurrentToast] = useState(null);
    const [newMessages, setNewMessages] = useState({});
    const [newcall, setNewCall] = useState(false);
    const [dialout, setdialout] = useState(false);
    const [callAccepted, setCallAccepted] = useState(false); 
    const [stream, setStream] = useState();  
    const [incomingCalls, setIncomingCalls] = useState([]);
    const [callInitiated, setCallInitiated] = useState(false);
    const [agentAvailable, setAgentAvailable] = useState(false);
    const [call, setCall] = useState({});
    const toastConfig = {
      position: "top-end", 
      autohide: true, 
      delay: 60000, // Duration in milliseconds
    }; 

    useEffect(() => {
      socket.emit('request-available-agents');  
      socket.emit('agent-connected', agentInfo);  
   
      // Listen for the server's response 
      socket.on('available-agents', (clientList) => {
          setAvailableAgents(clientList); // Assuming you are updating some state
      }); 
    
   }, [])


  


   useEffect(() => {
    socket.on('message', ({ message, roomId }) => {
      console.log('Received message:', message);
      setMessagesByRoom(prevMessages => ({...prevMessages,[roomId]: [...(prevMessages[roomId] || []), message]}));
      setNewMessages(prev => ({ ...prev, [roomId]: true }));
    });
    socket.on("room-ready", ({clientId, roomId, userInfo}) => { 
      console.log('Room ID:', roomId);
      setConnectedClients((prevClients) => [...prevClients,{ clientId, roomId, userInfo }]); 
    });
   },[socket]);

   useEffect(() => {
    socket.emit('join-room', 'agent-room');

    socket.on("room-ready", ({clientId, roomId, userInfo}) => { 
      console.log('Room ID:', roomId);
      setConnectedClients((prevClients) => [...prevClients,{ clientId, roomId, userInfo }]); 
    });
     // Cleanup 
    return () => {
      
      socket.emit("leave-room", "agent-room"); 
    }; 
}, [connectedClients]);

    useEffect(() => {
      // Listen for available rooms (from the backend)
      socket.on("update-available-rooms", (rooms) => {
        setAvailableRooms(rooms);
      }); 
      socket.on("client-waiting", ({ clientId, roomId, userInfo }) => {
        setWaitingClients((prevClients) =>
          prevClients.filter((client) => client.roomId !== roomId) 
        );
       setToastQueue(prevQueue => [...prevQueue, { clientId, roomId, userInfo }]); 
          console.log("Added to toast queue", currentToast);
          setCurrentToast( { clientId, roomId, userInfo });
      }); 
      

      /*  socket.on("callUser", ({ from, callerName, signalData }) => {
        console.log("Incoming call:", callerName); 
        setCall({ isReceivingCall: true, from, callerName, signalData });
       
     });  */

       // Handle answering the call
    
  
      return () => {
        // Cleanup on component unmount (disconnect)
        socket.disconnect();
        socket.off("connect");
        socket.off("disconnect"); 
        socket.off("message"); 
        socket.off('client-waiting');
      }; 
    }, []);

    useEffect(() => {  
      if (roomId) { 
          socket.on('callUser', ({ from, callerName, signalData, roomId, signal }) => {
              // Check if call already exists in `incomingCalls` 
              console.log(callerName);
              setNewCall(true);
              setCall({ isReceivingCall: true, from, name: callerName, roomId, signalData, signal }); 
              const existingCallIndex = incomingCalls.findIndex(call => call.from === from);
              if (existingCallIndex === -1) { 
                  // Add to the queue
                  setIncomingCalls(prevCalls => ([ 
                      ...prevCalls,  
                      {
                          from, 
                          callerName, 
                          isScreenSharing: signalData,
                          // Optional -  Add roomId: 
                          roomId: roomId || null // Pass assigned room Id if exists 
                      } 
                  ]));  
              } 
          });  
 
          return () => {
              socket.off('callUser'); 
           /*    if (stream) {
                  stream.getTracks().forEach(track => track.stop());
              }  */
          }; 
      }
  }, [roomId]);

    useEffect(() => {
      if (!currentToast && toastQueue.length > 0) { 
          const newQueue = [...toastQueue];
          const nextToast = newQueue.shift(); 
          setCurrentToast(nextToast);
          setToastQueue(newQueue); // Update state to re-render 
      } 
  }, [currentToast, toastQueue]); 
  
  const handleCurrentChat = (roomId, userInfo) => { 
    setRoomid(roomId);
    setUserInfo(userInfo);
    setCurrentChat(roomId);
  };

  const handleAcceptCall = (roomId) => { 
    setCallInitiated(true);
    setCallAccepted(true);
    setNewCall(false);
    setAgentAvailable(true);
    // ... (Emit "call-accepted" (or your equivalent), setCallAccepted(true) ,  and navigate to your CallWindow logic)
  };

  const handleDeclineCall = (roomId) => {
    setCallInitiated(false);
    setCallAccepted(false);
    setNewCall(false);
    setAgentAvailable(true);
   // Clear incoming call notifications on the decline from CallToast
   setIncomingCalls((prevCalls) => 
       prevCalls.filter((call) => call.roomId !== roomId || call.from !== roomId) // Use ID for matching  
   );
  };


    const handleAcceptRequest = (clientId, roomId, userInfo) => {
      // Join the selected room for chat
      setCurrentChat(roomId); 
      setRoomid(roomId);
      setUserInfo(userInfo);
      setWaitingClients(prevClients => 
        prevClients.filter(client => client.roomId !== roomId)
      );  
      setConnectedClients((prevClients) => [...prevClients,{ clientId, roomId, userInfo }]); 
      socket.emit("agent-joins-room", {roomId, agentInfo, userInfo}); 
      //setShowToast(false);
      setCurrentToast(null);  
      // ... navigate to the AgentChat or a similar component to start WebRTC
    };
    const handleSendMessage = (message) => { 
      socket.emit('rmessage',{roomid: roomId, message: message}); 
      setMessagesByRoom(prevMessages => ({...prevMessages,[roomId]: [...(prevMessages[roomId] || []), message]})); 
    };
  
    return (
       <>
        <div className="d-flex justify-content-center shadow bg-light border pb-2 pt-2 mb-1"> 
          <input className="nosubmit form-control" type="search" placeholder="Search..." />
        </div>
       <div className="row m-0" style={{height: `calc(100vh - 70px)`}}> {/* Ensure full viewport height  */} 

           {/* Left Sidebar (7%) */}
           <div className="list-group list-group-flush text-center bg-light" style={{width:"6%"}}>
         
                <div className="list-group-item list-group-item-action border-primary text-primary "> <FontAwesomeIcon icon = {faBell}/> <br/> <span style={{fontSize: '12px' }}>Activity</span> </div>
             
                <div className="list-group-item list-group-item-action border-primary text-primary" ><FontAwesomeIcon icon = {faCommentDots}/><br/> <span style={{fontSize: '12px' }}>Chats</span> </div>
              
                <div className="list-group-item list-group-item-action border-primary text-primary" ><FontAwesomeIcon icon = {faUserGroup}/><br/><span style={{fontSize: '12px' }}> Agents</span> </div>
              
                <div className="list-group-item list-group-item-action border-primary text-primary"><FontAwesomeIcon icon = {faPhone}/><br/> <span style={{fontSize: '12px' }}>Calls </span></div>
             
                <div className="list-group-item list-group-item-action border-primary text-primary"><FontAwesomeIcon icon = {faCalendar}/><br/><span style={{fontSize: '12px' }}> Calendar</span></div>
             
           </div>

           {/* Middle Section (35%) */}
           <div className="bg-light overflow-auto" style={{width:"24%", height:`calc(100vh - 70px)`, boxShadow: "-1px 0px 5px #888"}}>
           
                  {/* List available agents here:*/}  
                  <span>Recent Chats</span>
                  <div className="list-group mt-2 list-group-flush p-0">
                    {connectedClients.map(({ clientId, roomId, userInfo }) => (
                      <a className="list-group-item list-group-item-action flex-column align-items-start mt-1 border-0" key={roomId} onClick={() => handleCurrentChat(roomId, userInfo)}> 
                          {/* Client: {clientId} (Room: {roomId}) */}
                         
                          {userInfo.name} Client: {clientId}
                          {/* <button>Accept</button> */}
                          {newMessages[roomId] && <Badge bg="danger">New</Badge>} 
                      </a>

                    ))}
                  </div>

      {/*               
                   <div className="bg-light shadow-sm p-1 m-2">
                      <a className="nav-link active" aria-current="page" href="#">
                       Gopal <br /> The last Message.</a>
                    </div>
                    
                      
                    <div className="bg-light shadow-sm p-1 m-2">
                    <a className="nav-link" href="#">Murali <br /> The last Message.</a>
                    </div>
                 
                    <div className="bg-light shadow-sm p-1 m-2">   
                    <a className="nav-link" href="#">Vijay <br /> The last Message.</a>
                    </div> */}
                
                   
              
           
           </div> 

           {/* Main Content Area (60%) */}
           <div className="bg-body" style={{width:"70%", height:`calc(100vh - 70px)`, boxShadow: "-2px 0px 10px #888"}}>
           
            <AgentChat 
            roomId={roomId} 
            userInfo={userInfo}  
            onSendMessage={handleSendMessage}  
            messages={messagesByRoom[roomId] || []} 
            agentInfo={agentInfo}   
            />
           
              {/* Chat content area (could load conditionally 
               based on selected chat) or other agent actions. */}

              { callAccepted  && (
                            <AgentCallWindow 
                            socket={socket}
                            roomId={roomId} 
                            userInfo={userInfo}  
                            agentSocketId={socket.id}
                            agentAvailable={agentAvailable}  
                            messages={messages}
                            agentInfo={agentInfo}
                            dialout={dialout}
                            showModal={true}
                            setShowModal={setCallInitiated}
                            call={call}
                            /> 
                        )}
           </div> 
       </div> 
       <div>
       <Row className="">
        <Col> {/* ToastContainer will fill the available space in this column */}
          <ToastContainer className="p-3 toast-top-end">
            {toastQueue.map(({ clientId, roomId, userInfo }, index) => (
              <Toast 
                key={roomId} 
                onClose={() => { 
                  setToastQueue(prevQueue => prevQueue.filter(item => item.roomId !== roomId)); 
                  if (currentToast && currentToast.roomId === roomId) {
                      setCurrentToast(null);  // Clear the active toast if it was closed
                  }
                }}
                show={true} // Always show, managed by the array  
                delay={toastConfig.delay}
                autohide
              > 
                {/* Toast Content - Unchanged  */} 
                <Toast.Header> 
                  <strong className="me-auto">New Chat Request</strong>  
                </Toast.Header> 
                <Toast.Body >
                Chat request from  {userInfo.name}
                  <Button 
                      variant="primary" 
                      size="sm" 
                      className="ms-2" 
                      onClick={() => handleAcceptRequest(clientId, roomId, userInfo)}
                    > 
                      Accept 
                  </Button>
                </Toast.Body> 
              </Toast>  
            ))}  
          </ToastContainer>
        </Col>
      </Row>

      </div>

      { newcall && !callInitiated &&  (
             <CallToasts
                 incomingCalls={incomingCalls}
                 handleAcceptCall={handleAcceptCall} 
                 handleDeclineCall={handleDeclineCall}
             />  
         )}


       </>
    );
  };
  
  export default AgentDashboard;