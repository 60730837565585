// client/src/components/CallToasts.js
import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, Button } from 'react-bootstrap';
import Ringer from "./AgentRinger";  // Your new Toast-based component 

const AgentCallToasts = ({
    incomingCalls,
    handleAcceptCall,
    handleDeclineCall
}) => {

    // ... any states and useEffects
    const toastConfig = {
        position: "top-end", 
    };

    return (  
        <ToastContainer className="p-3" position={toastConfig.position}>
           {incomingCalls.map((call) => (
            <Ringer  
               key={call.id || call.from} // Replace with unique id
               callerName={call.callerName}
               onAccept={() => handleAcceptCall(call.roomId || call.from)}  
               onDecline={() => handleDeclineCall(call.roomId || call.from)}  
            /> 
           ))}
        </ToastContainer> 
    );  
};

export default AgentCallToasts; 