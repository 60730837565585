// client/src/components/SocketContext.js 
import React, { createContext, useState, useEffect } from 'react';
import io from 'socket.io-client'; 

const SocketContext = createContext(null);  

const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null); 

    useEffect(() => { 
        const newSocket = io('https://gtlucc.globaltelelinks.com:5000'); 
        setSocket(newSocket); 

        return () => { 
            newSocket.disconnect();  
        };  
    }, []);

    return (
        <SocketContext.Provider value={socket}> 
          {children}
        </SocketContext.Provider>
    ); 
};

export { SocketContext, SocketProvider }; 