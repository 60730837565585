// client/src/components/Ringer.js
import ringtone from "../../assets/sound/ringtone.mp3" 
import React, { useRef } from 'react'; 
import { Toast, ToastHeader, Button } from "react-bootstrap";  
//  … (other imports)

const AgentRinger = ({ callerName, onAccept, onDecline }) => {
  const audioRef = useRef(null); 
  return (
    <Toast onClose={onDecline} autohide={false}> 
        <Toast.Header>  
          <strong className="me-auto">Incoming Call</strong>  
        </Toast.Header>  
        <Toast.Body>  
            <audio ref={audioRef} src={ringtone} loop autoPlay /> 
            {callerName} is calling...  
            <div className="mt-2"> <Button variant="success" size="sm" onClick={onAccept} className="me-2">Accept</Button> <Button variant="danger" size="sm" onClick={onDecline}>Decline</Button> </div> 
        </Toast.Body>  
    </Toast>  
  );
};

export default AgentRinger;