// client/src/components/Ringer.js
import React from "react";
// ...  You'll likely add styling or use a CSS framework here 

const Ringer = ({ callerName, onAccept, onDecline }) => {
    return ( 
        <div className='ringer'>
          <p> Incoming call from {callerName}</p>  
          <div> 
              <button onClick={onAccept}>Accept</button>  
              <button onClick={onDecline}>Decline</button>
          </div> 
        </div>  
    );
};

export default Ringer;