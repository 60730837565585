// client/src/components/Agent/AgentChat.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import Peer from 'simple-peer';
import io from "socket.io-client"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faPaperPlane, faMicrophoneLines, faMicrophoneLinesSlash } from '@fortawesome/free-solid-svg-icons';
import { SocketContext } from "../SocketContext";

const AgentChat = ({ roomId, userInfo, onSendMessage, messages, agentInfo }) => { 
  
  const [inputValue, setInputValue] = useState(""); 
  const [peer, setPeer] = useState(null);
  const socket = useContext(SocketContext);
  const messagesEndRef = useRef(null); 
  
  useEffect(() => { 
    if (roomId) {
      socket.emit("join-room", roomId);
    }
  }, [roomId]); 

  // Handle signals from the client
  useEffect(() => {
    socket.on('client-signal', ({ signal, from }) => {  
     
    });
  
  }, []);


  useEffect(() => {
    scrollToBottom();  
  }, [messages]);

  const scrollToBottom = () => { 
    if (messagesEndRef.current) { 
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });  
    }  
  };

  const handleMessageChange = (e) => {
    setInputValue(e.target.value);
  }; 


  const handleSubmit = (event) => {
    event.preventDefault(); 
    if (inputValue.trim() !== '') { 
    //  sendMessage();
      onSendMessage({ user: 'Agent', text: inputValue });
      setInputValue('');
    }
  };
  const sendMessage = () => {
   
      const newMessage = {
        user: "Agent",  // Indicate message sender
        text: inputValue 
      };
      socket.emit('rmessage',{roomid: roomId, message: newMessage}); 
      setMessages(prevMessages => [...prevMessages, newMessage]); 
      setInputValue("");
    };
    
    const handleKeyDown = (e) => {  
      if (e.key === 'Enter') { 
        handleSubmit(e);  //  Call your existing submit handler 
      } 
  }; 

  return (
    <>
      <h3 className='p-3 border-bottom'>{userInfo.name} </h3>

      <div className='p-3 chat-messages' style={{height:`calc(100vh - 220px)`}}> 
        {messages.map((message, index) => ( 
          <div><b className='d-flex' style={{justifyContent: `${message.user === 'Agent' ? 'flex-end':'flex-start'}`}}>{message.user === 'Agent' ? agentInfo.name : userInfo.name}</b>
          <div key={index} className={`chat-message ${message.user === 'Agent' ? 'user-message' : 'bot-message'}`}>
            <div className='text-break'> <pre>  {message.text} </pre></div>
          </div>
          </div>
        ))}
         <div ref={messagesEndRef}></div>
      </div>
     
      <div>
      <form  onSubmit={handleSubmit} className="chat-input">
        <textarea
         style={{width: "90%", resize: false }}
          placeholder="Type your message..."
          value={inputValue}
          onChange={handleMessageChange} 
          onKeyDown={handleKeyDown}
          className='form-control'
        />
          <span className='pl-3 pr-3'> &nbsp;</span>
          <button type="submit"> <FontAwesomeIcon icon={faPaperPlane} /> </button>
        </form>
      </div> 
    </> 
  ); 
};  

export default AgentChat;