import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Your global styles
import App from './App'; 

import 'bootstrap/dist/css/bootstrap.min.css'; 

// Import Font Awesome CSS (or selectively import icons - see docs)
import '@fortawesome/fontawesome-svg-core/styles.css'; 
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false; // Tell Font Awesome to manage CSS itself 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 // <React.StrictMode>
    <App /> 
 // </React.StrictMode>
);