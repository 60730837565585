import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'; 
import Peer from "simple-peer";  
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';

function InCallChatWindow({ messages, onSendMessage, agentAvailable, roomId, userInfo, agentInfo, agentSocketId, onCallAgent }) {
  const [isTyping, setIsTyping] = useState(false);
  const timerRef = useRef(null);
  const messagesEndRef = useRef(null); 
  
  const showcallbutton = false;

  useEffect(() => {
    scrollToBottom();  
  }, [messages]);

  const scrollToBottom = () => { 
    if (messagesEndRef.current) { 
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });  
    }  
  };

  

  useEffect(() => {
    if (messages.length > 0) { 
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.user === 'You') { // Only start timer after user sends
        setIsTyping(true);
        timerRef.current = setTimeout(() => {
          setIsTyping(false); // Stop "typing" if no response after timeout
        }, 5000); // Adjust timeout as needed
      } else {
        // Clear the timer when bot/agent responds
        clearTimeout(timerRef.current);
        setIsTyping(false); 
      }
    }
  }, [messages]); 
  return (
   
    <div className="chat-window align-middle" style={{height:`calc(100vh - 80px)`}}>
      <div className="chat-messages">
        
        {messages.map((message, index) => (
          <ChatMessage key={index} message={message} userInfo={userInfo} agentInfo={agentInfo}/>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
     
      {isTyping && (
           <div className="typing-indicator">
           <span className="dot"></span> 
           <span className="dot"></span>
           <span className="dot"></span> 
         </div>
          )}
      <ChatInput onSendMessage={onSendMessage} agentAvailable={agentAvailable} agentSocketId={agentSocketId} onCallAgent={onCallAgent} showcallbutton= {showcallbutton} />
    </div>
 
  );
}

export default InCallChatWindow;