// client/src/components/UserChat.js 
import React, { useState, useEffect, useContext } from "react";
import io from "socket.io-client";
import PersonalInfo from "./PersonalInfo"; 
import ChatWindow from "./ChatWindow"; 
import CallWindow from "./CallWindow";
import Ringer from "./Ringer"; 
import { SocketContext } from "./SocketContext";


const UserChat = () => { 
    const [connected, setConnected] = useState(false); 
    const [showChat, setShowChat] = useState(false);
    const [messages, setMessages] = useState([]);
    const [agentAvailable, setAgentAvailable] = useState(false); 
    const [userInfo, setUserInfo] = useState(null); // Store user info
    const [agentInfo, setAgentInfo] = useState(null); // Store agent info
    const [assignedRoomId, setAssignedRoomId] = useState(null); 
    const [waitingForAgent, setWaitingForAgent] = useState(false);
    const [agentSocketId, setAgentSocketId] = useState(null);
    const [callInitiated, setCallInitiated] = useState(false);
    const [dialout, setdialout] = useState(false);
    
    const socket = useContext(SocketContext);
    useEffect(() => { 
      if (showChat && socket) { 
          socket.connect();  

          
          // Event Listeners (set once only after connection)
          socket.on('connect', () => {
              console.log('Connected to Socket.IO server (Client)');
              setConnected(true);
           
          }); 
          socket.on('disconnect', () => {
              console.log("Disconnected from Socket.IO Server");
              setConnected(false);
          }); 
          socket.on('message', (message) => setMessages(prev => [...prev, message]));
          socket.on('agent-available', (available) => { 
            console.log("Agent Availability Updated: ", available)
            setAgentAvailable(available); 
          });  
          socket.on('agent-joined', ({agentInfo, agentSocketId}) => { 
            console.log("Agent info: ", agentInfo)
            setAgentInfo(agentInfo); 
            setAgentSocketId(agentSocketId);
            setWaitingForAgent(false);
            setAgentAvailable(true); 
          });  
          socket.on("assigned-room", (roomId) => {
              setAssignedRoomId(roomId); 
              socket.emit('client-register',{userInfo: userInfo, roomId: roomId, socketid: socket.id});
          });
          
          socket.on("no-agent-available", () => {
            // Display a message to the user 
            setMessages(prevMessages => [ 
              ...prevMessages, 
              { user: 'System', text: 'No agents available at the moment. Please try again later or send us an email.'}
            ]);
        });


          // Cleanup 
          return () => {  
              socket.disconnect(); 
              // Remove listeners
              socket.off("connect");
              socket.off("disconnect"); 
              socket.off("message"); 
              socket.off("agent-available"); 
              socket.off("assigned-room");
              
          };
      }
  }, [showChat, socket]);

    const handleInfoSubmit = (userInfo) => { 
        console.log("User Info:", userInfo); 
        setConnected(true);
        setUserInfo(userInfo);
        setShowChat(true); 
       
    };

    const handleSendMessage = (message) => { 
      if (message.text.toLowerCase().includes("human support")) {
        socket.emit("request-agent", userInfo); // Or your custom event 
        setWaitingForAgent(true); // Show the animation

        //  Set up timeout for visual effect (clear it in other event handlers if needed)
        const timeoutId = setTimeout(() => {
          setWaitingForAgent(false);  
        }, 60000); // 1 minute 
        setMessages(prevMessages => [...prevMessages, { user: 'You', text: message.text}]); 
     //   setMessages(prevMessages => [...prevMessages, { user: 'System', text: 'Connecting you to an agent...'}]); 
        
        // (Optional) add a message: "Connecting you to an agent..."
        return; //  Don't send the original message to OpenAI  
    } 
    if(assignedRoomId)
    {
        socket.emit('umessage', {roomid: assignedRoomId, message: message}); 
        setMessages(prevMessages => [...prevMessages, message]); 
    }
    else{
        socket.emit('message', message); 
        setMessages(prevMessages => [...prevMessages, message]); 
    }
    };

   

  const handleCallAgent = () => {  
    setCallInitiated(true);  // Toggle to show  `CallWindow`
    setdialout(true);
    // ... You may need to emit an event here (`user-call-initiated`) 
    //  or trigger some server-side action if needed to let 
    // the agent know about the pending call, and start your 1 minute timer. 
    // (You’ll do this part in  server/utils/signalingServer.js or similar )

};

    // ... (add other functions as needed: handleRequestAgent, etc. ) 

    return (
        <div>
         { console.log(agentAvailable)}
            {/* Personal info first, then chat window  */}
            {!showChat && !connected && 
            <PersonalInfo onInfoSubmit={handleInfoSubmit} /> 
            } 


            {showChat && connected && !callInitiated && (
              <>
                <ChatWindow 
                  messages={messages}
                  onSendMessage={handleSendMessage} 
                  roomId={assignedRoomId} 
                  agentAvailable={agentAvailable}  
                  userInfo={userInfo}
                  agentInfo={agentInfo}
                  agentSocketId={agentSocketId}
                  onCallAgent ={handleCallAgent}
                  // ... pass other necessary props 
                /> 
                {waitingForAgent && (
                  <p>Connecting you to an agent <span className="waiting-for-agent"></span></p>
                )}
                { /* Display Ringer when call object has data 
                {call && (
                  <Ringer 
                      callerName={call.name} 
                      onAccept={answerCall}  
                      onDecline={declineCall}  
                  />  
                )} */ }  
                </>
             )}
             {callInitiated && (
                <CallWindow 
                roomId={assignedRoomId} 
                userInfo={userInfo}  
                agentSocketId={agentSocketId}
                agentAvailable={agentAvailable}  
                messages={messages}
                agentInfo={agentInfo}
                dialout={dialout}
                setMessages={setMessages}
                // ... (your other call-related states: call, callAccepted, callEnded, 
                //       and setter functions to update the states 
              />         
             )}                
            
     
        
     
      
               
            
            {/* ... add buttons or components for requesting agents, etc. */}
        </div>
    );  
}; 

export default UserChat; 