import React, { useState, useEffect, useRef, useContext } from "react";
import Peer from "simple-peer";  
import { SocketContext } from "./SocketContext"; 
import Ringer from "./Ringer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faVideo, faVideoSlash, faDesktop, faMicrophone, faMicrophoneSlash, faStop, faPhoneSlash} from '@fortawesome/free-solid-svg-icons';
// ... CSS or component imports ...
import InCallChatWindow from "./IncallChatWindow"; 
import ChatWindow from "./ChatWindow";

const CallWindow = ({   roomId, userInfo, agentSocketId, agentAvailable, messages, agentInfo, dialout, setMessages}) => {
    const socket = useContext(SocketContext); 
    const [stream, setStream] = useState(null);  
    const [isVideoOn, setIsVideoOn] = useState(true);  
    const [isAudioOn, setIsAudioOn] = useState(true);  
    const [isScreenSharing, setIsScreenSharing] = useState(false);  
    const agentVideo = useRef(null); 
    const userVideo = useRef(null);  
    const connectionRef = useRef(null);  
    const [callAccepted, setCallAccepted] = useState(false); 
    const [callEnded, setCallEnded] = useState(false);
    const [call, setCall] = useState({}); 
    const [peer, setPeer] = useState(null);

    useEffect(() => {
      
      if(dialout)
      {
        setCallAccepted(false);
        const constraints = { 
          audio: {echoCancellation: true}, 
          video: { facingMode: "user" } // Try to get user-facing camera 
      };

        navigator.mediaDevices.getUserMedia(constraints)
        .then((currentStream) => { 
          setStream(currentStream); 
          console.log("Current Stream", currentStream);
          console.log("User Call initiated Video Call to Agent"); 
          socket.emit("user-request-call", {roomId, userInfo});
          userVideo.current.srcObject = currentStream; 
        }) 
        .catch((err) => { 
          console.log('Error getting user media:', err);
    
          // Likely no camera (or permission issue). Try audio only
          navigator.mediaDevices.getUserMedia({ audio: {echoCancellation: true} })  
            .then(audioOnlyStream => {
              setStream(audioOnlyStream);
              console.log("audioOnlyStream", audioOnlyStream);
              console.log("User Call initiated audio Call to Agent"); 
              socket.emit("user-request-call", {roomId, userInfo});
              userVideo.current.srcObject = audioOnlyStream; 
            })
            .catch(audioErr => { 
              console.log('Error getting audio only stream:', audioErr);  
              alert("Could not access microphone. Please check your permissions.")
            })
        });
      }
      else
      {
          if(call.isReceivingCall){
            setPeer(null);
            const peer = new Peer({initiator: false, trickle: false, stream: stream });  
            setPeer(peer);
            socket.emit("answerCall", { signal: data, to: roomId });
          
            peer.on("stream", (stream) => { 
              // Set the remote user’s stream if a stream exists AND  the peerVideo is ready 
              if (agentVideo.current) {  
                agentVideo.current.srcObject = stream;   
            }
          });

            peer.on("signal", (data) => {
              console.log("Peer signal :", data);
            //  peer.signal(data);
             socket.emit('client-signal', { signal: data, to: agentSocketId, roomId: roomId }); 
            });
        
          connectionRef.current = peer;
        }
     }

     socket.on("callAccepted", () => { 
      console.log("Agent accepted the call");
      setCallAccepted(true);
      setCallEnded(false);
    });

   
},[]); 


   useEffect(() => {
      if(callAccepted){
      console.log(callAccepted);
      console.log(callEnded);
      
      console.log("stream: ",stream);
          //initiating peer for communication
    const peer = new Peer({ initiator: true, trickle: false, stream: stream });
    setPeer(peer);
    peer.on("signal", (data) => {
     // peer.signal(data); 
     socket.emit("client-signal", ({ signal: data, to: agentSocketId, roomId }));
    
    });

    peer.on("stream", (currentStream) => { 
      console.log("getting stream from agent");
           // Set the remote user’s stream if a stream exists AND  the peerVideo is ready 
        if (agentVideo.current) {  
          agentVideo.current.srcObject = currentStream;
        }  
    });

    connectionRef.current = peer;
    socket.on("client-signal-from", ({ signal, from, roomId }) => { // Adapted listener
      console.log("client signal received from :", from);
        if(peer){
              peer.signal(signal);
        }
          }); 

    peer._debug = console.log;
    
  }
  }, [callAccepted]); 



    // Hang up (or leave call)
    const leaveCall = () => { 
        setCallEnded(true);
        socket.emit('call-ended',roomId);
        // Optional: Reset local stream (especially if doing video) 
        try{
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());  
          }
        } 
        catch(err)
          {
            console.log("Error clearing stream : ", err);
          } 
    
        try{
        //  Other Cleanup:  
        if (connectionRef.current) { 
        //  connectionRef.current.destroy(); // Clean up the  `peer` instance
          setPeer(null);
             
          }
        } 
        catch(err)
        {
          console.log("Error clearing connectionRef :", err);
        }

       window.location.reload(); 
    }; 
  
    const toggleVideo = () => { 
      const videoTrack = stream.getVideoTracks()[0];  
      if (videoTrack.enabled) { 
        videoTrack.enabled = false;
        setIsVideoOn(false); 
      } else { 
          videoTrack.enabled = true; 
          setIsVideoOn(true); 
      }  
    };
  
    const toggleAudio = () => { 
      const audioTrack = stream.getAudioTracks()[0]; 
      if (audioTrack.enabled) {  
          audioTrack.enabled = false;
          setIsAudioOn(false); 
      } else {
          audioTrack.enabled = true;
          setIsAudioOn(true);
      }
    };
  
    const startScreenShare = async () => {  
      setIsScreenSharing(true);
      // Start capturing the screen  
      try {
        const capturedStream = await navigator.mediaDevices.getDisplayMedia({ video: true });  
        // Replace the current video track with the screen capture 
        const videoTracks = stream.getVideoTracks();
        if (videoTracks.length > 0) { 
            const sender = connectionRef.current.peerConnection.getSenders().find(sender => sender.track.kind === 'video');
            if (sender) { 
              sender.replaceTrack(capturedStream.getVideoTracks()[0])
              .then(() => {  
                // Stop the old track  
                videoTracks[0].stop();
              })
              .catch(err => console.error('Error replacing video track', err)); 
            } 
        } 
      } catch (err) {
        console.error("Error accessing screen share:", err);
      }
    };
  
    const stopScreenShare = () => { 
      // ... Logic to stop sharing, similar to ChatWindow example
      navigator.mediaDevices.getUserMedia({ video: true })
      .then(webcamStream => {  
        const sender = connectionRef.current.peerConnection.getSenders().find(sender => sender.track.kind === 'video'); 
        sender.replaceTrack(webcamStream.getVideoTracks()[0])
          .then(() => {  
            setIsScreenSharing(false);  
          })
          .catch(err => console.error('Error replacing with webcam track', err));
      }) 
      .catch(err => console.error('Error getting webcam stream:', err));
    }; 

    const handleSendMessage = (message) => { 
       // setMessages(prevMessages => [...prevMessages, { user: 'You', text: message.text}]); 
    if(roomId)
    {
        socket.emit('umessage', {roomid: roomId, message: message}); 
        setMessages(prevMessages => [...prevMessages, message]); 
    }
    else{
        socket.emit('message', message); 
        setMessages(prevMessages => [...prevMessages, message]); 
    }
    };
    
  
  // ...  JSX   (Very similar to your ChatWindow component)
    return ( 
        <div className='call-window'>
            {/* (Style as you need!)  */}
           
           { call.isReceivingCall &&  !callAccepted &&  (
              <Ringer 
                  callerName={call.name}
                  onAccept={answerCall}  // Pass the answerCall function 
                  onDecline={declineCall} // Pass the declineCall function 
              />
          ) } 

          
              {/* Conditional rendering - Show buttons only when call is in progress */ } 
        {/*  { callAccepted && !callEnded && ( */}
        
          <div className="row">
            <div className="call-controls p-1">
              <div className="float-end">
                <button onClick={toggleVideo}> 
                      <FontAwesomeIcon icon={isVideoOn ? faVideo : faVideoSlash} />
                      { isVideoOn ? "Hide Video" : "Show Video" }
                  </button> 
                  <span className="pl-5">&nbsp;</span>
                  <button onClick={toggleAudio}>  
                      <FontAwesomeIcon icon={isAudioOn ? faMicrophone : faMicrophoneSlash} /> 
                      { isAudioOn ? "Mute" : "Unmute" }
                  </button>
                  <span className="pl-5">&nbsp;</span>
                  { !isScreenSharing && (
                  <button onClick={startScreenShare}>
                      <FontAwesomeIcon icon={faDesktop} /> 
                      Share Screen
                  </button>
                  )}
                <span className="pl-5">&nbsp;</span>
                  {isScreenSharing && (
                    <button onClick={stopScreenShare}>
                      <FontAwesomeIcon icon={faStop} /> 
                      Stop Sharing 
                  </button>  
                  )}  
                  <span className="pl-5">&nbsp;</span>
                  <button onClick={leaveCall}>  
                      <FontAwesomeIcon icon={faPhoneSlash} />  
                      Hang Up  
                  </button>
                  </div>
            </div>
          </div>
              {/* Videos - Adapt JSX if needed  */ }
              <div className="row">
                  <div className="col-md-8">
                  
                  <video playsInline ref={userVideo} autoPlay className="videowindow" id="user_window"/>
                  <video  playsInline ref={agentVideo} autoPlay className="videowindow" id="agent_window"  /> 
                    {/* Display users’s video */ } 
                  {/*   {callAccepted ? (
                    <video  playsInline ref={agentVideo} autoPlay className="videowindow"  /> ) : null 
                    } 

                    {/*agent video */}
                   {/*  {stream && <video playsInline ref={userVideo} autoPlay className="videowindow"/>}  */}
                  </div>
                  <div className="col-md-4">
                  <InCallChatWindow 
                      messages={messages}
                      onSendMessage={handleSendMessage} 
                      roomId={roomId} 
                      agentAvailable={agentAvailable}  
                      userInfo={userInfo}
                      agentInfo={agentInfo}
                      agentSocketId={agentSocketId}
                    
                      // ... pass other necessary props 
                    /> 
                  </div>
              </div>
            
        {/*  )} */}
        </div> 
    );  
};  
  
export default CallWindow;  