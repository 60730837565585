import React, { useState, useEffect, useRef } from 'react';

import PersonalInfo from './components/PersonalInfo'; 
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import UserChat from './components/UserChat';
import AgentLogin from './components/Agent/AgentLogin'; 

import { SocketProvider } from "./components/SocketContext"; 


 // Connect to backend

function App() {
 
  return (
  <SocketProvider> 
    <BrowserRouter>
     <Routes>
        {/* Client Chat Route - Should ONLY show PersonalInfo initially */}
        <Route path="/" element={<UserChat />} />
          {/* Agent Routes - No conditional rendering needed within these */}
        <Route path="/agent" element={<AgentLogin />} />
      </Routes>
   </BrowserRouter>
  </SocketProvider> 
  );
}

export default App;