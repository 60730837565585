import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCommentDots, faPause, faPlay, faStop } from '@fortawesome/free-solid-svg-icons'; 
import Peer from "simple-peer";  
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';
import { Button } from 'react-bootstrap';

function ChatWindow({ messages, onSendMessage, agentAvailable, roomId, userInfo, agentInfo, agentSocketId, onCallAgent }) {
  const [isTyping, setIsTyping] = useState(false);
  const [timer, setTimer] = useState(null); 
  const timerRef = useRef(null);
  const messagesEndRef = useRef(null); 
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const showcallbutton = true;

  useEffect(() => {
    scrollToBottom();  
  }, [messages]);

  const scrollToBottom = () => { 
    if (messagesEndRef.current) { 
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });  
    }  
  };

  const handlePauseSpeech = () => {  
    window.speechSynthesis.pause(); 
    setIsPaused(true);
 }  

 const handleResumeSpeech = () => {
    window.speechSynthesis.resume();
    setIsPaused(false);
 }; 

 const handleStopSpeech = () => {
  console.log("Stopping TTS");
    window.speechSynthesis.cancel(); //  "cancel()"   stops AND clears the queue 
    setIsSpeaking(false);
    setIsPaused(false); 
 } 

 
 const handleSpeechState = (speechstate) => {  
  
  switch(speechstate.state)
    {
      case "0":
        setIsSpeaking(false);
        console.log("Speaking false :", speechstate.state);
        break;
      case "1":
        setIsSpeaking(true);
        console.log("Speaking true :", speechstate.state);
        break;
      case "2":
          setIsPaused(true);
          console.log("Paused true :", speechstate.state);
          break;
      case "3":
          setIsPaused(false);
          console.log("Paused false :", speechstate.state);
          break;
      default:
        break;
    }
   
} 

useEffect(() => {
  // Update the page based on isSpeaking and isPaused
  console.log("isSpeaking", isSpeaking);
  if (isSpeaking) {
    // Show the player-controls
    document.getElementById('player-controls').style.display = 'block';
  } else {
    // Hide the player-controls
    document.getElementById('player-controls').style.display = 'none';
  }
 
}, [isSpeaking]);

  useEffect(() => {
    if (messages.length > 0) { 
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.user === 'You') { // Only start timer after user sends
        setIsTyping(true);
        timerRef.current = setTimeout(() => {
          setIsTyping(false); // Stop "typing" if no response after timeout
        }, 5000); // Adjust timeout as needed
      } else {
        // Clear the timer when bot/agent responds
        clearTimeout(timerRef.current);
        setIsTyping(false); 
      }

      
    }

 

  }, [messages]); 


  return (
    <div>
      <h1 className='text-center'>Welcome to Global Telelinks Support</h1>
    <div className="chat-window align-middle">
      <div className="chat-messages">
        
        {messages.map((message, index) => (
          <ChatMessage key={index} message={message} userInfo={userInfo} agentInfo={agentInfo} onSpeaking = {handleSpeechState}/>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
     
      {isTyping && (
           <div className="typing-indicator">
           <span className="dot"></span> 
           <span className="dot"></span>
           <span className="dot"></span> 
         </div>
          )}
      <ChatInput onSendMessage={onSendMessage} agentAvailable={agentAvailable} agentSocketId={agentSocketId} onCallAgent={onCallAgent} showcallbutton={showcallbutton}  />
    </div>
    <div className='position-absolute top-0 end-0 mt-5'> 
           {/* Only show while speaking */}
           <div id="player-controls" className="border border-1">
            <span className='pl-3'>&nbsp;</span>
           <Button variant="light" size="sm" onClick={handleStopSpeech} disabled={!isSpeaking}>  
             <FontAwesomeIcon icon={faStop} /> 
             &nbsp;Stop  
           </Button>  
           <span className='pl-3'>&nbsp;</span>
           {!isPaused && (
              <Button  variant="light"  size="sm" onClick={handlePauseSpeech} disabled={!isSpeaking} className='ml-2' > 
                <FontAwesomeIcon icon={faPause} />
                &nbsp; Pause 
              </Button>
           )}  
           <span className='pl-3'>&nbsp;</span>
           {isPaused &&  (  
               <Button variant="light" size="sm"  onClick={handleResumeSpeech} disabled={!isSpeaking} className='ml-2'>  
                 <FontAwesomeIcon icon={faPlay} /> 
                 &nbsp;  Resume 
              </Button>  
           )}
       </div> 
       </div>
    </div>
  );
}

export default ChatWindow;