import React,{ useEffect, useState  } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRobot, faHeadphones, faMagic } from "@fortawesome/free-solid-svg-icons"; 


function ChatMessage({ message, userInfo, agentInfo, onSpeaking }) {
  // Simple styling for user and bot messages
  const messageClass = message.user === 'You' ? 'user-message' : 'bot-message';
  const synth = window.speechSynthesis;

  let userIcon = null; 
  let username = null;
  // Conditional Icon based on User 
  if (message.user === 'You') { 
    userIcon = faUser;
    username = userInfo.name;
  } else if (message.user === 'Bot') {
    userIcon = faRobot;
    username = 'Bot';
  } else if (message.user === 'Agent') {
    userIcon = faHeadphones;
    username = agentInfo.name;
  } else if (message.user === 'System') {
    userIcon = faMagic;
    username = 'System'
  }  

  /* const playtts = (text) => {
    talkify.config = {
      debug: true, //true to turn on debug print outs
      useSsml: true, //true to turn on automatic HTML to SSML translation. This should give a smoother reading voice (https://en.wikipedia.org/wiki/Speech_Synthesis_Markup_Language)
      maximumTextLength: 5000, //texts exceeding this limit will be splitted into multiple requests
      remoteService: {
          host : 'https://talkify.net',
          apiKey: 'b8a0d8e0-274b-4214-be72-e19492e550fd',
          active: true //True to use Talkifys language engine and hosted voices. False only works for Html5Player.
      },
       ui:
      {
          audioControls: { //disable to provide your own player or use Talkify headless.
              enabled: false,
              controlcenter: "modern", //["modern", "classic", "local", "native"]
              container: document.body,
              voicepicker: {
                  enabled: true, //Applicable on modern and classic control centers
                  filter: {
                      byClass: [], //Not applicable for Html5Player,  example: ["Standard", "Premium", "Exclusive", "Neural"]
                      byCulture: [], //example: ["en-EN", "en-AU"]
                      byLanguage: [] //Not applicable for Html5Player, example: ["English", "Spanish"]
                  }
              }
          }
      }, 
      keyboardCommands: { //Ctrl + command
          enabled: false,
          commands: { // Configure your own keys for the supported commands
              playPause: 32,
              next: 39,
              previous: 37
          }
      },
      voiceCommands: {
          enabled: false,
          keyboardActivation: { //Ctrl + command
              enabled: true,
              key: 77
          },
          commands: { // Configure your own phrases for the supported commands
              playPause: ["play", "pause", "stop", "start"],
              next: ["play next", "next"],
              previous: ["play previous", "previous", "back", "go back"]
          }
      },
      formReader: {
          voice: "Zira", //TTS voice name if remote service otherwise Web Speech API voice object
          rate: 0, //See possible values for each tyoe of player down below
          remoteService: true,
          //Below is the default texts for the form.
          requiredText: "This field is required",
          valueText: "You have entered {value} as: {label}.",
          selectedText: "You have selected {label}.",
          notSelectedText: "{label} is not selected."
      },
      autoScroll: {
          offsetpx: 100 //number of pixels offset from window top
      }
  }
    var player = new talkify.Html5Player(); //or new talkify.TtsPlayer();
    //var player = new talkify.TtsPlayer();
   // player.forceVoice({ name: 'Zira', description: "Zira" });
    player.playText(text);
  }; */

 let voices;
 async function getVoiceList() {
   voices = await synth.getVoices();
  console.log(voices);
}

  const playtts = async (text) => {
    console.log("TTS Started");
    
    const isSafari = navigator.userAgent.includes("Safari") &&  !navigator.userAgent.includes("Chrome");  
    const isEdge = navigator.userAgent.includes("Edg"); 

   // synth.onvoiceschanged = () => {
      console.log("Synth voice change fired");
      await getVoiceList();
      //const voices = synth.getVoices();
      const voiceOptions = {
        default: voices[0], 
        heera: voices.find(voice => voice.name.includes('Heera')),
        samantha: voices.find(voice => voice.name.includes('Samantha')),  // Find by name  (Safari might have variations!)
        jenny:  voices.find(voice => voice.name.includes("Microsoft Jenny"))
      };

       console.log("Safari", isSafari);
       console.log("Edge", isEdge);
       const selectedVoice =  isSafari 
                                 ? voiceOptions.heera || voiceOptions.samantha || voiceOptions.default  // Prefer  Samantha on Safari  
                                 : ( isEdge  
                                      ? voiceOptions.jenny || voiceOptions.default // Microsoft Jenny  (or default)  for Edge
                                      : ( voiceOptions.heera || voiceOptions.default)  // Otherwise  Microsoft Heera or Default
                                  ) ; 

        
       // Create  Utterance 
       const utterance = new SpeechSynthesisUtterance(text); 
       utterance.voice = selectedVoice; 

         console.log("Selected Voice", selectedVoice);  // Always good to debug to make sure 
      //     it's working as you expect!  
    
      if(isSafari || isEdge)
        {
          utterance.rate = 1;  //  Set these *after* assigning the voice to ensure they get applied
          utterance.pitch = 1; 
        }
      else 
      {
        utterance.rate = 1.8;  //  Set these *after* assigning the voice to ensure they get applied
        utterance.pitch = 1; 
      }

        
       utterance.onstart = (event) => {
        onSpeaking({ state: '1' });
        console.log("speech started");
      };
      utterance.onend = (event) => {
        onSpeaking({ state: '0' });
        console.log("speech ended");

      };
      utterance.onpause = (event) => {
        onSpeaking({ state: '2' });
        console.log("speech paused");
      };
      utterance.onresume  = (event) => {
        onSpeaking({ state: '3' });
        console.log("speech resumed");
      };
       synth.speak(utterance); 
   // }; 
  

   // Trigger  getVoices:
    //synth.getVoices(); 
  }; 

  useEffect(() => {
    console.log("new message from :", message.user);
    if(message.user != "You"){
    playtts(message.text.replace(/[*]|[#]/g, ''));
    }
  }, [message]);


  return (
 
     <div>
       <div className="icon-container d-flex"  style={{justifyContent: `${message.user === 'You' ? 'flex-end':'flex-start'}`}}>
       {username}
           {/*   {userIcon && ( <FontAwesomeIcon icon={userIcon} className="message-icon" /> )} */}
       </div>
    <div className={`chat-message ${messageClass}` }>
    <div className='text-break'> <pre>  {message.text}</pre></div>
    </div>
    </div>
     
     
  );
}

export default ChatMessage;