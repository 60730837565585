import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal, Button } from 'react-bootstrap'; 
import Peer from "simple-peer";  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faVideo, faVideoSlash, faDesktop, faMicrophone, faMicrophoneSlash, faStop, faPhoneSlash, faClose, faWindowMaximize, faWindowMinimize, faWindowClose} from '@fortawesome/free-solid-svg-icons';


// ... CSS or component imports ...
//import InCallChatWindow from "./IncallChatWindow"; 

const AgentCallWindow = ({ socket,  roomId, userInfo, agentSocketId, agentAvailable, messages, agentInfo, dialout, showModal, setShowModal, call}) => {
    
    const [stream, setStream] = useState();  
    const [isVideoOn, setIsVideoOn] = useState(true);  
    const [isAudioOn, setIsAudioOn] = useState(true);  
    const [isScreenSharing, setIsScreenSharing] = useState(false);  
    const agentVideo = useRef(null); 
    const userVideo = useRef(null);  
    const connectionRef = useRef(null);  
    const [callAccepted, setCallAccepted] = useState(false); 
    const [callEnded, setCallEnded] = useState(false);
    const [peer, setPeer] = useState(null);
    
    const [showCallWindow, setshowCallWindow] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    // Handle mousedown event on modal header (for dragging)
    
   useEffect(() => {
      setshowCallWindow(showModal);
    },[showModal]);
 

    useEffect(() => {
      if(dialout)
      {
        setCallAccepted(true);
        setPeer(null);
        const peer = new Peer({ initiator: true, trickle: false, stream: stream });
        setPeer(peer);

        peer.on("signal", (data) => {
          console.log("Agent Call initiated to Agent", data); 
          socket.emit("agent-request-call", {roomId, userInfo, signal: data}); 
          //peer.signal(signal); 
        });
    
        peer.on("stream", (currentStream) => { 
          userVideo.current.srcObject = currentStream;
        });
      
       connectionRef.current = peer;
        
      }
      else{
        console.log("Call :", call);
        if(call.isReceivingCall){
            try{  
              setIsScreenSharing(call.signalData);
              //setPeer(null);
              setCallAccepted(true);
              setCallEnded(false);
              socket.emit("answerCall", {to: roomId});

              console.log(callAccepted);
              console.log(callEnded);
              
             const constraints = { 
              audio: {echoCancellation: true}, 
              video: { facingMode: "user" } // Try to get user-facing camera 
          };
         

          navigator.mediaDevices.getUserMedia(constraints)
            .then((currentStream) => { 
              setStream(currentStream); 
              agentVideo.current.srcObject = currentStream; 

              const peer = new Peer({ initiator: false,  trickle: false, stream: currentStream });
              setPeer(peer); 
              peer._debug = console.log;

              peer.on("signal", (data) => {
                console.log("Peer signal data :", data)
                socket.emit("agent-signal",{signal: data, to:call.from, roomId: roomId});
                 });

             peer.on("stream", (currentStream) => { 
                          // Set the remote user’s stream if a stream exists AND  the peerVideo is ready 
             console.log("getting stream from client");            
              if (userVideo.current) {  
                  userVideo.current.srcObject = currentStream;
              }  
               });
               
           connectionRef.current = peer;
           socket.on("agent-signal-from", ({ signal, from, roomId }) => { // Adapted listener
            console.log("agent signal received from :", from);
            peer.signal(signal);
                   
                 });
         
            }) 
            .catch((err) => { 
              console.log('Error getting user media:', err);
      
              // Likely no camera (or permission issue). Try audio only
              navigator.mediaDevices.getUserMedia({ audio: {echoCancellation: true}  })  
                .then(audioOnlyStream => {
                  setStream(audioOnlyStream);
                  agentVideo.current.srcObject = audioOnlyStream; 
                  const peer = new Peer({ initiator: false,  trickle: false, stream: audioOnlyStream });
                  setPeer(peer); 
                  peer._debug = console.log;

                  peer.on("signal", (data) => {
                    console.log("Peer signal data :", data)
                    socket.emit("agent-signal",{signal: data, to:call.from, roomId: roomId});
                     });

                 peer.on("stream", (currentStream) => { 
                 console.log("getting stream from client");            
                  if (userVideo.current) {  
                      userVideo.current.srcObject = currentStream;
                  }  
                   });

                   connectionRef.current = peer;
                   socket.on("agent-signal-from", ({ signal, from, roomId }) => { // Adapted listener
                    console.log("agent signal received from :", from);
                    peer.signal(signal);
                           
                         });
                 
                })
                .catch(audioErr => { 
                  console.log('Error getting audio only stream:', audioErr);  
                  //  Handle the error appropriately, for example: 
                  alert("Could not access microphone. Please check your permissions.")
                })
            });
            console.log("stream: ",stream);
            
            }

              catch(err){
                console.log("Peer error :", err);
              }
            } 
      }

   

    }, []); 

      // Hang up (or leave call)
    const leaveCall = () => { 
        setCallEnded(true);
        socket.emit('call-ended',roomId);
        try{
          if (stream) {
            stream.getTracks().forEach((track) => track.stop());  
            }
          } 
          catch(err)
            {
              console.log("Error clearing stream : ", err);
            } 
      
          try{
          //  Other Cleanup:  
          if (connectionRef.current) { 
            setPeer(null);
               // connectionRef.current.destroy(); // Clean up the  `peer` instance
            }
          } 
          catch(err)
          {
            console.log("Error clearing connectionRef :", err);
          } 
        setShowModal(false);
        setshowCallWindow(false);
    }; 
  
    const toggleVideo = () => { 
      const videoTrack = stream.getVideoTracks()[0];  
      if (videoTrack.enabled) { 
        videoTrack.enabled = false;
        setIsVideoOn(false); 
      } else { 
          videoTrack.enabled = true; 
          setIsVideoOn(true); 
      }  
    };
  
    const toggleAudio = () => { 
      const audioTrack = stream.getAudioTracks()[0]; 
      if (audioTrack.enabled) {  
          audioTrack.enabled = false;
          setIsAudioOn(false); 
      } else {
          audioTrack.enabled = true;
          setIsAudioOn(true);
      }
    };
  
    const startScreenShare = async () => {  
      setIsScreenSharing(true);
      // Start capturing the screen  
      try {
        const capturedStream = await navigator.mediaDevices.getDisplayMedia({ video: true });  
        // Replace the current video track with the screen capture 
        const videoTracks = stream.getVideoTracks();
        if (videoTracks.length > 0) { 
            const sender = connectionRef.current.peerConnection.getSenders().find(sender => sender.track.kind === 'video');
            if (sender) { 
              sender.replaceTrack(capturedStream.getVideoTracks()[0])
              .then(() => {  
                // Stop the old track  
                videoTracks[0].stop();
              })
              .catch(err => console.error('Error replacing video track', err)); 
            } 
        } 
      } catch (err) {
        console.error("Error accessing screen share:", err);
      }
    };
  
    const stopScreenShare = () => { 
      // ... Logic to stop sharing, similar to ChatWindow example
      navigator.mediaDevices.getUserMedia({ video: true })
      .then(webcamStream => {  
        const sender = connectionRef.current.peerConnection.getSenders().find(sender => sender.track.kind === 'video'); 
        sender.replaceTrack(webcamStream.getVideoTracks()[0])
          .then(() => {  
            setIsScreenSharing(false);  
          })
          .catch(err => {
            console.error('Error replacing with webcam track', err);
            setIsScreenSharing(false);
         });
      }) 
      .catch(err => { 
        console.error('Error getting webcam stream:', err);
        setIsScreenSharing(false);
      }
      );
    }; 

  
    const handleSendMessage = (message) => { 
        //setMessages(prevMessages => [...prevMessages, { user: 'You', text: message.text}]); 
    if(roomId)
    {
        socket.emit('umessage', {roomid: roomId, message: message}); 
        setMessages(prevMessages => [...prevMessages, message]); 
    }
    else{
        socket.emit('message', message); 
        setMessages(prevMessages => [...prevMessages, message]); 
    }
    };
    

      const handlecallwindowminimize = () => { 
        const windowElement = document.querySelector('.window');
        windowElement.style.position = 'absolute';
        windowElement.style.height = '30px';
        windowElement.style.top = '90%';
        windowElement.style.left = '50%';
     };
      const handlecallwindowmaximize = () => { 
        const windowElement = document.querySelector('.window');
        const originalWidth = 800;
        const originalHeight = 600;
        windowElement.style.position = 'absolute';
        windowElement.style.top = '50%';
        windowElement.style.left = '50%';
        windowElement.style.width = originalWidth + 'px';
        windowElement.style.height = originalHeight + 'px';
      };

      const handlecallwindowclose = () => { 
       setShowConfirmModal(true);
      };

      const handleCallClose = () => {
        const windowElement = document.querySelector('.window');
        windowElement.remove();
      };

  // ...  JSX   (Very similar to your ChatWindow component)
    return ( 
          <div className="call-window" style={{zIndex: 14999}}>
      { showCallWindow && (
     
        <div className='window' style={{display: showModal? 'block' : 'none'}}>
        <div className="window-header">
            <span>Call Window</span>
            <div className="window-buttons">
                <button className="minimize-btn" onClick={handlecallwindowminimize}><FontAwesomeIcon icon={faWindowMinimize} /></button>
                <button className="maximize-btn" onClick={handlecallwindowmaximize}><FontAwesomeIcon icon={faWindowMaximize} /></button>
                {/* <button className="close-btn" onClick={handlecallwindowclose}><FontAwesomeIcon icon={faWindowClose} /></button> */}
            </div>
        </div>
        <div className="window-body">
        
		
        <div className="row">
            <div className="call-controls p-1">
              <div className="float-end">
                <button type="button" onClick={toggleVideo}> 
                      <FontAwesomeIcon icon={isVideoOn ? faVideo : faVideoSlash} />
                      { isVideoOn ? "Hide Video" : "Show Video" }
                  </button> 
                  <span className="pl-5">&nbsp;</span>
                  <button type="button" onClick={toggleAudio}>  
                      <FontAwesomeIcon icon={isAudioOn ? faMicrophone : faMicrophoneSlash} /> 
                      { isAudioOn ? "Mute" : "Unmute" }
                  </button>
                  <span className="pl-5">&nbsp;</span>
                  { !isScreenSharing && (
                  <button type="button" onClick={startScreenShare}>
                      <FontAwesomeIcon icon={faDesktop} /> 
                      Share Screen
                  </button>
                  )}
                <span className="pl-5">&nbsp;</span>
                  {isScreenSharing && (
                    <button type="button" onClick={stopScreenShare}>
                      <FontAwesomeIcon icon={faStop} /> 
                      Stop Sharing 
                  </button>  
                  )}  
                  <span className="pl-5">&nbsp;</span>
                  <button type="button" onClick={leaveCall}>  
                      <FontAwesomeIcon icon={faPhoneSlash} />  
                      Hang Up  
                  </button>
                  </div>
            </div>
          </div>
              {/* Videos - Adapt JSX if needed  */ }
          <div className="row">
                  <div className="col-md-8">
                    {/* Display agent’s video */ } 
                    <video  playsInline ref={agentVideo} autoPlay className="videowindow" id="agent_window"  />
                    {/* {callAccepted ? () : null 
                    }  */}

                    {/* Your own video */}
                    {stream && <video playsInline ref={userVideo} autoPlay className="videowindow" id="user_window" />}
                  </div>
                   <div className="col-md-4">
                 {/* <InCallChatWindow 
                      messages={messages}
                      onSendMessage={handleSendMessage} 
                      roomId={roomId} 
                      agentAvailable={agentAvailable}  
                      userInfo={userInfo}
                      agentInfo={agentInfo}
                      agentSocketId={agentSocketId}
                    
                      // ... pass other necessary props 
                    />  */}
                  </div>
              </div>
            
        {/*  )} */}
    </div>
    <Modal show={showConfirmModal} > 
            <Modal.Header closeButton onClick={handleCallClose}> {/* The "x" (close) button on the Modal  */ } 
                <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>

            <Modal.Body> 
                <p>Are you sure to disconnect the call?</p>  
            </Modal.Body>  

            <Modal.Footer>
              <Button variant="secondary" onClick={() => {
                  setShowConfirmModal(false); // Hide the modal 
              }}>
                  Cancel  
              </Button> 
              <Button variant="primary" onClick={() => {
                  handlecallwindowclose();
                  setShowConfirmModal(false); // Hide the modal 
              }}>  
                  Confirm
              </Button>  
            </Modal.Footer>
        </Modal> 
    </div>
   
    )}
    </div>
    );  
};  
  
export default AgentCallWindow;  