import React, { useState, useEffect,useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faPaperPlane, faMicrophoneLines, faMicrophoneLinesSlash, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { SocketContext } from './SocketContext';

function ChatInput({ 
  onSendMessage, agentAvailable, roomId, agentSocketId, onCallAgent, showcallbutton }) {
  
  const [inputText, setInputText] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const mediaRecorderRef = useRef(null);
  const recognitionRef = useRef(null);
  const formRef = useRef(null); 
  //const socket = useContext(SocketContext);
  const [hasEnabledVoice, sethasEnabledVoice] = useState(false);
  const synth = window.speechSynthesis;
  
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); 
    enableSpeechSynthesis();
    if (inputText.trim() !== '') { 
      onSendMessage({ user: 'You', text: inputText });
      setInputText('');
    }
  };

const enableSpeechSynthesis = () => {
    //alert(hasEnabledVoice);
    if (!hasEnabledVoice){
      const lecture = new SpeechSynthesisUtterance('hello');
      lecture.volume = 0;
      synth.speak(lecture);
      sethasEnabledVoice(true);
     // alert("Enabling Speech Synthesis");
      }
  };
  
  const handleCall = (event) =>{
    event.preventDefault(); 
    onCallAgent();
  };

  let transcriptionTimer;
  const handleMicrophoneClick = async () => {
    if (isRecording) {
      recognitionRef.current.stop();
      setIsRecording(false);
    } 
    else 
    {
	 enableSpeechSynthesis();
      let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition || null;
      //const recognition = new webkitSpeechRecognition() || new SpeechRecognition();
      const recognition = SpeechRecognition ? new SpeechRecognition() : null; 
      if(recognition)
      {
            recognition.continuous = true;
            recognition.interimResults = true;
            recognition.lang = 'en-US'; // Replace with desired language

          recognitionRef.current = recognition;
          setIsRecording(true);
          recognition.onresult = (event) => {
            let interimTranscript = '';
            for (let i = event.resultIndex; i < event.results.length; i++) {
              const transcriptPart = event.results[i][0].transcript;
              if (event.results[i].isFinal) {
                // Handle final transcript
                setInputText(transcriptPart);
                //formRef.current.submit();
                  onSendMessage({ user: 'You', text: transcriptPart });
                  setInputText('');
            
              } else {
                interimTranscript += transcriptPart;
                setInputText(interimTranscript); // Update interim transcript
              }
            }
          };

          recognition.onerror = (error) => {
            console.error('Speech recognition error:', error);
          };

          recognition.onend = () => {
            console.log('Speech recognition ended');
            recognitionRef.current.stop();
            setIsRecording(false);
          };

          

          recognition.start();

            /*navigator.mediaDevices.getUserMedia({ audio: true })
              .then(stream => {
                const mediaRecorder = new MediaRecorder(stream);   

                mediaRecorderRef.current = mediaRecorder;

                mediaRecorder.start();
                setIsRecording(true);   


                const audioChunks = [];
                mediaRecorder.ondataavailable = e => {
                  audioChunks.push(e.data);
                };

                mediaRecorder.onstop = () => {
                  console.log("mediaRecorder stopped");
                  mediaRecorderRef.current = null;
                  stream.getTracks().forEach(track => track.stop());
                  setIsRecording(false);
                  const audioBlob = new Blob(audioChunks);
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const audioData = e.target.result;
                    const recognition = new webkitSpeechRecognition() || new SpeechRecognition();
                    recognition.interimResults = false;
                    recognition.continuous = false;
                    recognition.lang = 'en-US'; // Set language
                  // const transcriptionTimeout = 50000; // Timeout in milliseconds

                  let transcript = '';
                    recognition.onresult = (event) => {
                      const current = event.results[event.resultIndex][0].transcript;
                      //transcript += current;
                      setInputText(current);
                      console.log('Transcript:', transcript);
                    // clearTimeout(transcriptionTimer); // Clear timeout if transcription is successful
                    };

                              

                    recognition.onerror = (error) => {
                      console.error('Speech recognition error:', error);
                    };
                    recognition.onend = () => {
                      // Handle end of speech or recognition
                      console.log('Speech recognition ended');
                      // For one-time recognition, clear transcript:
                      // transcript = '';
                    };

                    const audioBlobURL = URL.createObjectURL(audioBlob);
                    const audio = new Audio(audioBlobURL);
                    audio.play();  // Play the audio for speech recognition
                    recognition.start();
                //  transcriptionTimer = setTimeout(() => {
                      // Handle transcription timeout
                    // console.log('Transcription timed out');
                    //  recognition.stop(); // Stop speech recognition
                  //  }, transcriptionTimeout); 
                    
                  };
                  reader.readAsArrayBuffer(audioBlob);
                };
              })
              .catch(error => {
                console.error('Error accessing microphone:', error);
              });
              */
      } 
      else{
        console.log("Speech recognition unsupported");
      }

    }
  };

  const handleKeyDown = (e) => {  
    if (e.key === 'Enter') { 
      handleSubmit(e);  //  Call your existing submit handler 
    } 
}; 

  return (
    <form ref={formRef} onSubmit={handleSubmit} className="chat-input">
     <textarea
        style={{width: "90%", resize: false }}
        placeholder="Type your message..."
        value={inputText}
        onChange={handleInputChange}
        id="txt_input"
        onKeyDown={handleKeyDown}
        className='form-control'
      />  
     
      <span className='pl-3 pr-3'> &nbsp;</span>
      <Button
                onClick={handleMicrophoneClick} 
                variant='success'
                id="recordButton"
              >
                {isRecording ? <FontAwesomeIcon icon={faMicrophoneLinesSlash} /> : <FontAwesomeIcon icon={faMicrophoneLines} />}
              </Button>  
      <span className='pl-3 pr-3'> &nbsp;</span>

      <Button
                variant='success'
                type="submit"
              >
                <FontAwesomeIcon icon={faPaperPlane} /> 
              </Button>  
      <span className='pl-3 pr-3'> &nbsp;</span>
      { agentAvailable && showcallbutton &&  ( 
              <Button
                onClick={handleCall} 
                disabled={!agentSocketId}  
                variant='success'
                
              >
                <FontAwesomeIcon icon={faPhone} /> 
              </Button>  
          )}  
    </form>
  );
}

export default ChatInput;