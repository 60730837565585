import React, { useState, useContext } from "react";
import axios from 'axios';
import io from "socket.io-client"; 
import AgentDashboard from "./AgentDashboard";
import { SocketContext } from "../SocketContext";



const AgentLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null); 
  const [userloggedin, setUserLoggedin] = useState(false);
  const [agentInfo,setagentInfo] = useState('');
  
  const socket = useContext(SocketContext);

  
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://gtlucc.globaltelelinks.com:5000/api/agent/login', {
        username, 
        password
      });

      if (response.data.token) { 
        // Login Successful
        // 1. Store the JWT (e.g., in localStorage) 
        localStorage.setItem('agentToken', response.data.token); 
        // 2. Redirect (navigate) to agent dashboard
        //    Example using react-router-dom's useNavigate:
            //const navigate = useNavigate();
           // navigate('/agent/dashboard');  
        setUserLoggedin(true);
        localStorage.setItem('agentdata', response.data.agentData.agent.name)
     
        console.log("Agent Socket created!"); 
        setagentInfo(response.data.agentData.agent);
      
        socket.connect();
      } else { 
        // Handle login errors
        setErrorMessage('Invalid username or password'); 
      }

    } catch (error) {
      console.error("Login error:", error); 
      setErrorMessage('An error occurred during login. Please try again.'); 
      socket.disconnect();
    }
  };

  return (
    <>
   
    {userloggedin && (
      <AgentDashboard socket={socket} agentInfo = {agentInfo}></AgentDashboard>
    )}
      {!userloggedin &&
      <div className="container position-absolute top-50 start-50 translate-middle"> 
      <div className="row justify-content-center">
      {errorMessage && <div className="text-center text-danger">{errorMessage}</div>} {/* Display Error */}
       <div className="col-md-3 border border-primary rounded-2 shadow mb-5 bg-body">
      <div className="row bg-primary bg-gradient text-white mb-2 s"><p className="mt-1 mb-1 text-center"> Please login to your account </p></div>
     
      <form onSubmit={handleLogin} > 
       
       <div className='col-md-4'>
          <label htmlFor="username" className="form-label"> Username: </label>
        </div>
        <div className='col-md-12'>
          <input 
            type="text" 
            id="username" 
            className="form-control col-md-4"
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
          />
        </div>
        
       
       <div className='col-md-4'>
          <label htmlFor="Password" className="form-label">Password: </label>
        </div>
        <div className='col-md-12'>
          <input 
            type="password" 
            id="password" 
            className="form-control col-md-4"
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
        </div>
        
        {/* Password input field */}
        <div className="m-4">
        <button type="submit" className="btn btn-outline-primary">Login</button>
        </div>
        
      </form>
      </div>
      </div>
      </div>
     }
   
    </>
  );
}

export default AgentLogin;