import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faUser, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import ChatWindow from './ChatWindow';
import VideoChat from './VideoChat';



function PersonalInfo( { onInfoSubmit } ) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  /* const handleSubmit = (event) => {
    event.preventDefault();
    handleInfoSubmit({ name, email, phoneNumber });
  }; */

  const [messages, setMessages] = useState([]);
  const [connected, setConnected] = useState(false);
  const [agentAvailable, setAgentAvailable] = useState(false);
  const [inVideoChat, setInVideoChat] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [userInfo, setUserInfo] = useState(null); // Store user info
  // ... other state variables and functions

  /*const handleInfoSubmit = (userInfo) => {
    console.log("User Information:", userInfo); // Log user info
    setUserInfo(userInfo); // Store user info
    setShowChat(true); 
  };

  
  useEffect(() => {
    socket.on('connect', () => setConnected(true));
    socket.on('disconnect', () => setConnected(false));
    socket.on('message', (message) => setMessages((prev) => [...prev, message]));
    socket.on('agent-available', (available) => setAgentAvailable(available));
    
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('message');
      socket.off('agent-available');
    };
  }, []);

  const handleSendMessage = (message) => {
    socket.emit('message', message);
    setMessages(prevMessages => [...prevMessages, message]);
  };

  const handleRequestAgent = () => {
    socket.emit('request-agent');
  };

  const handleVideoChat = (inChat) => {
    setInVideoChat(inChat);
  };
*/
const handleSubmit = (event) => {
  event.preventDefault(); 
  onInfoSubmit({ name, email, phoneNumber });
};

  return (
    <>
      {/* {showChat && ( 
        <>
      <ChatWindow messages={messages} onSendMessage={handleSendMessage} />
    
      {agentAvailable && !inVideoChat && (
        <button onClick={handleRequestAgent}>Talk to an Agent</button>
      )}
      {inVideoChat && <VideoChat onHangup={() => handleVideoChat(false)} />}
      </>
      )}
      {!connected && <div>Connecting...</div>}
      {!showChat && */}
      
    <div className="container position-absolute top-50 start-50 translate-middle"> 
    <h1 className='text-center'>Welcome to Global Telelinks Support</h1>
      <div className='row justify-content-center'>
        <div className='col-md-4 border border-primary border border-2 rounded-2 bg-light'>
        <div className="row bg-primary bg-gradient text-white mb-5"><p className="mt-1 mb-1 text-center"> Your Information </p></div>
      
      <form onSubmit={handleSubmit} className="row g-3">
        <div className="row mb-4">
        <div className='col-md-4 text-right'>
          <label htmlFor="name" className="form-label"><FontAwesomeIcon icon={faUser} />  Name:</label>
          </div>
          <div className='col-md-8'>
          <input 
            type="text" 
            className="form-control col-md-8"
            id="name" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            required 
          />
          </div>
        </div>
        <div className="row mb-4">
        <div className='col-md-4 text-right'>
          <label htmlFor="email" className="form-label"><FontAwesomeIcon icon={faEnvelope} />  Email:</label>
        </div>
        <div className='col-md-8'>
          <input 
            type="email"
            className="form-control col-md-8" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
          </div>
        </div>
        <div className="row mb-4">
        <div className='col-md-4 text-right'>
          <label htmlFor="phoneNumber"className="form-label"><FontAwesomeIcon icon={faPhone} />  Phone:</label>
          </div>
          <div className='col-md-8'>
          <input 
            type="tel" 
            id="phoneNumber" 
             className="form-control col-md-8"
            value={phoneNumber} 
            onChange={(e) => setPhoneNumber(e.target.value)} 
            required 
          />
          </div>
        </div>
        <div className="col-md-8 offset-md-4 mb-4">
        <button type="submit" className='btn btn-outline-primary'>Enter Chat</button>
        </div>
      </form>
      </div>
      </div>
    </div>
{/* } */}
    </>
  );
}

export default PersonalInfo;